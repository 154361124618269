import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

function Kontakt() {
  const [submissionStatus, setSubmissionStatus] = React.useState("");
  // Sends form data to formspree.io
  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const response = await fetch("https://formspree.io/f/xlevpvze", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: new FormData(form),
    });
    form.reset();
    // set submission status
    if (response.status == 200) {
      setSubmissionStatus("Tack för ditt meddelande!");
    }
    else {
      setSubmissionStatus("Något gick fel, försök igen!");
    }
  };
  const siteTitle = "Fornbod - Kontakt";

  return (
    <Layout>
      <Helmet title={siteTitle} />
      <div className="wrapper">
        <h1>Kontakta oss</h1>
        <p className="contactText">
          Fyll i formuläret nedan så återkommer vi så fort vi kan.
        </p>
        <div className="contactForm">
          <form name="contact" onSubmit={handleSubmit}>
            <input name="namn" placeholder="Namn" type="text" />
            <input name="email" placeholder="Email" type="email" />
            <textarea name="meddelande" placeholder="Meddelande..."></textarea>
            <p className="submissionStatus">{submissionStatus}</p>
            <button className="contactButton" type="submit">
              Skicka
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Kontakt;
